/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Manrope", sans-serif;
}

.code-text {
  color: #a0aec0;
  font-size: 0.800em;
}

.bg-danger .code-text {
  color: #5c262b;
}

.pb-0_7 {
  padding-bottom: 0.7rem !important;
}

.timepicker-dial__control {
  width: 70px !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
  background: #a2b0ff !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #455cad !important;
}

.discount {
  background-color: rgba(167, 162, 163, 0.1) !important;
}

.isStop {
  background-color: rgba(220, 76, 100, 0.1) !important;
}

.text-error {
  margin-left: -16px;
}

.reports-menu{
  .mat-mdc-menu-content {
    padding: 0;
  }
}
